<template>
  <div>
    <dashboard-page-title :showAddBtn="false" :showFilter="true" :showSearch="true" :showExportation="true">بوليصات التأمين</dashboard-page-title>

    <main-table :baseURL="VUE_APP_INSURANCE_LINK" :fields="fields" list_url="insurances"></main-table>
  </div>
</template>

<script>
import { VUE_APP_INSURANCE_LINK } from '@/config/constant'
export default {
  data () {
    return {
      VUE_APP_INSURANCE_LINK,
      fields: [
        { label: 'ID', key: 'id', class: 'text-right' },
        { label: 'رقم البوليصة', key: 'paied_number', class: 'text-right' },
        { label: 'اسم العميل', key: 'full_name', class: 'text-right' },
        { label: 'المبلغ المؤمن عليه', key: 'total_price', class: 'text-right' },
        { label: 'قيمة التأمين', key: 'total_price', class: 'text-right' },
        { label: 'تاريخ انتهاء البوليصة', key: 'date', class: 'text-right' },
        { label: 'الفاتورة', key: 'service_provider.fees', class: 'text-right' },
        { label: 'التحكم', key: 'actions', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' }
      ],
      matone: {
        matoneNumber: 1,
        value: 500000,
        mount: 20,
        minmount: 300,
        certificate: 24,
        tax: 15,
        prices: [
          { from: 0, to: '', precent: '', disabled: true, error: '' }
        ]
      }
    }
  },
  methods: {
    addNewRow () {
      this.matone.prices.push({ from: '', to: '', precent: '' })
    },
    decrease () {
      if (this.matone.matoneNumber > 1) {
        this.matone.matoneNumber--
      }
    }
  }
}
</script>
